import { Areas, Provinces, Zones } from 'model/City'
import { stringify } from 'querystring'
import { objectToQueryString } from 'utils/objectToQueryString'

import { buildPropertySearchUrlParams, PropertySearchParams } from '../../model/PropertySearchParams'

export const Routes = {
  adminDetail: (slug: string) => `/admin/properties/${slug}`,
  adminDraftDetail: (slug: string) => `/admin/drafts/${slug}`,
  adminDraftNew: () => '/admin/drafts/new',
  adminLogin: () => '/admin/login',
  adminProperties: () => '/admin/properties',
  userLogin: (redirectPath?: string) => `/user/login${redirectPath ? `?redirectPath=${redirectPath}` : ''}`,
  userLogout: () => '/user/logout',
  userDashboard: () => '/account',
  userForgotPassword: (params?: { redirectPath?: string }) => `/user/forgot-password${params ? objectToQueryString(params) : ''}`,
  userResetPassword: (token: string, flow: string) => `/user/verify?token=${token}&flow=${flow}`,
  userPurchasingTimeline: () => '/user/purchasing-timeline',
  loginMessage: (email: string) => `/user/login-message?email=${email}`,
  propertyDetails: (slug: string) => `/properties/${slug}`,
  notFound: () => {
    return Routes.landing() // TODO: Place actual route of the 404 page
  },
  blog: () => 'https://blog.libeen.com',
  landing: () => {
    return '/' // TODO: Place the landing page
  },
  howItWorks: () => '/how-it-works',
  reviews: () => '/reviews',
  properties: (data?: {
    wizardParams?: {
      city?: string
      roomsAmount?: string
      pricePerMonthRange?: string
      neighborhoods?: string
    }
    propertySearchParams?: PropertySearchParams
  }) => {
    const { wizardParams, propertySearchParams } = data ?? {}
    if (wizardParams) {
      return `/properties?${stringify(wizardParams)}`
    }

    if (propertySearchParams) {
      return `/properties?${buildPropertySearchUrlParams(propertySearchParams)}`
    }

    return '/properties'
  },
  wizardCities: (params?: { redirectPath: string }) => `/wizard${params ? objectToQueryString(params) : ''}`,
  wizardZoneSelect: (params: {
    province: Provinces
    zone?: Zones
    area?: Areas
    enabled?: boolean
    custom?: boolean
    redirectPath?: string
  }) => {
    const queryString = objectToQueryString(params)
    return `/wizard/zone-select${queryString}`
  },
  wizardNoProperties: (params: { city: string; name: string; custom: boolean }) => `/wizard/noproperties?${stringify(params)}`,
  wizardUnknownCity: (params?: { redirectPath?: string }) => `/wizard/unknowncity${params ? objectToQueryString(params) : ''}`,
  wizardSignup: (params: {
    province: Provinces
    zone?: Zones
    area?: Areas
    enabled?: boolean
    custom?: boolean
    countryCode?: string
    redirectPath?: string
  }) => `/wizard/signup?${stringify(params)}`,
  wizardSuccess: () => '/wizard/success',
  purchaseIndex: (id: string) => `/purchase?propertyId=${id}`,
  purchaseOwnerInfo: (purchaseSlug: string) => `/purchase/step-one/information?purchaseSlug=${purchaseSlug}`,
  purchaseAdditionalOwners: (purchaseSlug: string, currentOwner = 2) =>
    `/purchase/step-one/additional-owners?purchaseSlug=${purchaseSlug}&currentOwner=${currentOwner}`,
  purchaseOwnerDocumentation: (purchaseSlug: string, currentOwner = 1) =>
    `/purchase/step-one/documentation?purchaseSlug=${purchaseSlug}&currentOwner=${currentOwner}`,
  purchaseOwnerEmploymentInfo: (purchaseSlug: string, currentOwner = 1) =>
    `/purchase/step-one/employment-info?purchaseSlug=${purchaseSlug}&currentOwner=${currentOwner}`,
  purchaseReservationPayment: (purchaseSlug: string) => `/purchase/step-one/reservation-payment?purchaseSlug=${purchaseSlug}`,
  purchaseStatus: (purchaseSlug: string) => `/purchase/status?purchaseSlug=${purchaseSlug}`,
  purchaseStepTwo: (purchaseSlug: string) => `/purchase/step-two?purchaseSlug=${purchaseSlug}`,
  adminDealflow: () => `/admin/purchases`,
  adminSmarthousers: () => `/admin/smarthousers`,
  adminPurchaseDetail: (purchaseSlug: string) => `/admin/purchases/${purchaseSlug}`,
  adminSmarthouserDetail: (smarthouserSlug: string) => `/admin/smarthousers/${smarthouserSlug}`,
  logout: () => '/user/logout',
  myAccount: () => '/account',
  owners: () => '/owners',
  about: () => '/about',
  faq: () => '/faq',
  agency: () => '/agency',
  privacy: () => '/privacy',
  legal: () => '/legal',
  cookies: () => '/cookies',
  workWithUsForm: () => '/about#careers',
  whatsApp: () => 'https://wa.me/34627065603',
  linkedin: () => 'https://www.linkedin.com/company/libeen/',
  facebook: () => 'https://www.facebook.com/Libeen.smarthousing',
  instagram: () => 'https://www.instagram.com/libeen.smarthousing/',
  youtube: () => 'https://www.youtube.com/channel/UCFyMcdL7NlcEt__eEZ8KMTQ',
  twitter: () => 'https://twitter.com/LibeenSmart',
  messenger: () => 'http://m.me/Libeen.smarthousing',
  tiktok: () => 'https://www.tiktok.com/@libeensmarthousing',
}
